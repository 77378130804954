import { get } from 'lodash';
import { FETCH_SIDEMENU_CONFIG } from '../actions/actionTypes';

export const sidemenu = (state = {}, action) => {
  if (action.type === FETCH_SIDEMENU_CONFIG) {
    let data = [];
    const resData = get(action, 'value.data.data.findSidebarMenuByUser');
    if (resData) {
      // TODO: Hard coded for adding care journal in sidemenu, will be removed in future
      resData.data.push({
        ID: 57,
        active: true,
        fkNavigation: 3,
        icon: null,
        label: 'Care Journal',
        path: 'care-journal',
      });

      resData.data.push({
        ID: 57,
        active: true,
        fkNavigation: 3,
        icon: null,
        label: 'Digital Shared Display',
        path: 'whiteboard-care-journal',
      });
      const sidemeMenuArr = resData.data.slice();
      const retArr = [];

      sidemeMenuArr.forEach(eachItem => {
        let hasSubMenu = false;
        const dMenu = Object.assign({}, eachItem);
        dMenu.submenu = [];

        sidemeMenuArr.forEach(eachSubMenu => {
          if (eachItem.ID === eachSubMenu.fkNavigation) {
            hasSubMenu = true;
            dMenu.submenu.push(eachSubMenu);
          }
        });

        if (!hasSubMenu) {
          delete dMenu.submenu;
        }

        if (!eachItem.fkNavigation) {
          retArr.push(dMenu);
        }
      });

      data = retArr;
    }

    return Object.assign({}, state, {
      ...action.value,
      data,
    });
  }

  return state;
};

export default sidemenu;
